import { Radio } from '@change/design-system/components/forms';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

type Props = {
	detailText: string;
	label: string;
	logoSrc: string;
	onClick: () => void;
	selected: boolean;
	type: string;
	isVariant?: boolean;
};

export function PaymentSelectorButton({
	detailText,
	label,
	logoSrc,
	onClick,
	selected,
	type,
	isVariant,
}: Props): React.JSX.Element {
	return (
		<Box
			sx={{
				display: 'block',
				fontWeight: 'semi-bold',
				borderWidth: selected ? '2px' : '1px',
				// Blue tint color isn't available in th new design system, so using rbga to calculate the background tint.
				// eslint-disable-next-line no-nested-ternary
				backgroundColor: selected ? 'rgba(21, 125, 185, 0.08)' : isVariant ? undefined : 'highlight',
				borderColor: selected ? 'accent' : 'neutral-grey600',
				borderRadius: 'standard',
				width: '100%',
				height: isVariant ? ['60px', '60px'] : ['40px', '40px'],
				marginBottom: 8,
				justifyContent: isVariant ? 'center' : undefined,
				alignContent: isVariant ? 'center' : undefined,
				paddingTop: isVariant ? 4 : 0,
			}}
			variant="bordered"
			onClick={onClick}
			data-testid={`payment-source-button-${type}`}
		>
			<Flex>
				<Flex ml={16} sx={{ flex: 1, width: '100%' }} my={8}>
					<Radio
						size="big"
						label={
							<Flex ml={16} sx={{ width: '100%' }}>
								<Flex sx={{ flex: '1' }}>
									<Flex>
										{!detailText && (
											<Text
												as="span"
												size={['small', 'small', 'default']}
												fontWeight="semibold"
												sx={{ textTransform: 'capitalize' }}
											>
												{label}
											</Text>
										)}
									</Flex>
									<Flex>
										<Text as="span" size={['caption', 'small', 'default']}>
											{detailText}
										</Text>
									</Flex>
								</Flex>
							</Flex>
						}
						checked={selected}
						readOnly
					/>
				</Flex>

				<Flex ml={16} sx={{ justifyContent: 'end' }} my={8}>
					<Box sx={{ width: '45px' }}>
						<img alt="" width="30px" height="20px" src={logoSrc} />
					</Box>
				</Flex>
			</Flex>
		</Box>
	);
}
